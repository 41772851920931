e4.bootstrap.modal = {
    init: function () {
        e4.bootstrap.modal.bindEvents();
    },
    bindEvents: function () {
        //TODO: Laves om så der i stedet tages højde for om modal'en ligger i et element med Translate og will-change.
        var strSelector = [ e4.settings.scrollReveal.selector, '.' + e4.settings.scrollReveal.classAnimateInOnLoad, '.' + e4.settings.scrollReveal.classAnimateInOnScroll ].join( ',' );
        jQuery( '.modal' ).on( 'show.bs.modal', function () {
            jQuery( strSelector ).addClass( e4.settings.scrollReveal.classResetAnimate );
            // jQuery( this ).focus();//( 'focus' );
            //console.log( this, document.activeElement );
        } );
        //jQuery( '.modal' ).on( 'shown.bs.modal', function () {
            //jQuery( strSelector ).addClass( e4.settings.scrollReveal.classResetAnimate );
            //jQuery( this ).focus();//( 'focus' );
            //console.log( this, document.activeElement );
        //} );
        jQuery( '.modal' ).on( 'hidden.bs.modal', function () {
            jQuery( strSelector ).removeClass( e4.settings.scrollReveal.classResetAnimate );
        } );
    }
};

