var e4 = e4 || {};
( function () {
    'use strict';
    e4.settings = {
        lang: ( function () {
            return jQuery( 'html' ).attr( 'lang' );
        }() ),

        animation: {
            speed: 375
        },
        navigation: {
            autoHideTolerance: {
                up: 25,
                down: 'auto'
            },
            revealActive: {
                delay: 400,
                speed: 0.75,
                easing: 'swing'
            }
        },
        parallax: {
            speed: -0.35
        },
        scrollReveal: {
            selector: '.js-e-scroll-reveal .e-section',
            classAnimateInOnLoad: 'animate-in-on-load',
            classAnimateInOnScroll: 'animate-in-on-scroll',
            classResetAnimate: 'reset-animate'
        },
        scrollToAnchor: {
            speed: 0.5,
            nudge: 2,
            easing: 'swing',
            stickyElements: '.js-e-header',
            fullscreenElements: '.p-section-fullscreen, .p-banner-fullscreen',
            backToTop: {
                revealOffset: 1.5
            }
        },
        forms: {
            validate: {
                messages: {
                    da: {
                        required: 'Skal udfyldes'
                    }
                }
            }
        },
        search: {
            delay: {
                min: 100,
                max: 1000
            },
            result: {
                pages: {
                    show: true,
                    url: '/system/data/search?q='
                },
                products: {
                    show: false,
                    url: '/system/data/products?q=',
                    showAllLink: '/shop/search?q=',
                    max: {
                        xs: 2,
                        sm: 3,
                        md: 4,
                        lg: 5,
                        xl: 5
                    }
                }
            }
        },
        require: {
            jQueryValidate: {
                test: 'window.jQuery.fn.validate',
                url: '//cdnjs.cloudflare.com/ajax/libs/jquery-validate/1.16.0/jquery.validate.min.js',
                fallback: '/files/system/espresso/design/js/vendor/jquery.validate.min.js',
                callback: null
            },
            handlebars: {
                test: 'window.Handlebars',
                url: '//cdnjs.cloudflare.com/ajax/libs/handlebars.js/4.0.5/handlebars.min.js',
                fallback: '/files/system/espresso/design/js/vendor/handlebars.min.js',
                callback: null // Register helper and compile templates
            },
            // GoogleMaps: {
            //     test: 'window.google.maps',
            //     url: '//maps.googleapis.com/maps/api/js?key={0}',
            //     fallback: null,
            //     callback: null
            // }
            MarkerClusterer: {
                test: 'window.MarkerClusterer',
                url: '//cdnjs.cloudflare.com/ajax/libs/js-marker-clusterer/1.0.0/markerclusterer.js', //_compiled
                fallback: '/files/system/espresso/design/js/vendor/markerclusterer_compiled.js',
                callback: null
            },
            jQueryUImap: {
                test: 'window.jQuery.fn.gmap',
                url: '//cdnjs.cloudflare.com/ajax/libs/jquery-ui-map/3.0-rc1/min/jquery.ui.map.min.js',
                fallback: '/files/system/espresso/design/js/vendor/jquery.ui.map.min.js',
                callback: null
            },
            typeahead: {
                test: 'window.jQuery.fn.typeahead',
                url: '//cdnjs.cloudflare.com/ajax/libs/corejs-typeahead/1.1.1/typeahead.jquery.min.js',
                fallback: '/files/system/espresso/design/js/vendor/typeahead.jquery.min.js',
                callback: null
            },
            places: {
                test: 'window.places',
                url: '//cdn.jsdelivr.net/places.js/1/places.min.js',
                fallback: '/files/system/espresso/design/js/vendor/places-v1.4.12.min.js',
                callback: null
            },
            drawSVG: {
                test: 'window.jQuery.fn.drawSVG',
                url: 'https://cdn.jsdelivr.net/jquery.drawsvg/1.1.0/jquery.drawsvg.min.js',
                fallback: '/files/system/espresso/design/js/vendor/jquery.drawsvg.min.js',
                callback: null
            }
        },
        ecom: {
            cart: {
                url: '/system/data/cart',
                msgHideDelay: 10000
            },
            checkout: {
                url: '/system/data/checkout',
                delay: 500,
                multipageBreakpoint: 'lg',
                shipping: {
                    glsID: 'SHIP2',
                    pacsoftID: 'SHIP5',
                    searchCount: 6
                },
                termsAndConditions: '/shop/terms-and-conditions'
            }
        },
        breakpoint: {
            xs: 0,
            sm: 544,
            md: 768,
            lg: 922,
            xl: 1200
        },
        collapse: {
            peekSizeMin: 1.5
        },
        dealersearch: {
            showListOnLoad: true,
            zoom: {
                min: 12,
                max: 15,
                initial: 6
            },
            results: {
                min: 2,
                sensitivity: 0.5
            },
            country: {
                DK: {
                    center: {
                        lat: 56.26392,
                        lng: 9.501785
                    },
                    zoom: 7
                },
                NO: {
                    center: {
                        lat: 60.47202399999999,
                        lng: 8.468945999999999
                    },
                    zoom: 6
                },
                SE: {
                    center: {
                        lat: 60.12816100000001,
                        lng: 18.643501
                    },
                    zoom: 5
                },
                DE: {
                    center: {
                        lat: 51.165691,
                        lng: 10.451526
                    },
                    zoom: 6
                },
                UK: {
                    center: {
                        lat: 55.378051,
                        lng: -3.435973
                    },
                    zoom: 4
                }
            }
        }
    };
}() );

