e4.cookies = {
    init: function () {
        var jCookieMessage = jQuery( '.e-cookie-wrapper' );
        if ( jCookieMessage.length === 1 ) {
            //TODO: Lav nye e4 class names
            jCookieMessage.find( '.CookieOptInLevelConfig' ).on( 'click', function ( event ) {
                event.preventDefault();
                jQuery.get( this.href );
                jCookieMessage.addClass( 'accepted' );
            } );
        }
    }
};

