var DwSpamScripts = DwSpamScripts || {};

e4.param = ( function () {
    var vars = [],
        hash,
        hashes = window.location.href.slice( window.location.href.indexOf( '?' ) + 1 ).split( '&' );
    for ( var i = 0; i < hashes.length; i++ ) {
        hash = hashes[ i ].split( '=' );
        vars.push( hash[ 0 ] );
        vars[ hash[ 0 ] ] = hash[ 1 ];
    }
    return vars;
} )();

e4.forms = {
    init: function () {
        if ( jQuery( '.js-e-form-dw, form[data-validate="true"]' ).length > 0 ) {
            e4.util.require.js( e4.settings.require.jQueryValidate, function () {
                e4.forms.validate.init();
                e4.forms.mailchimp.init();
            } );
        }
    },
    validate: {
        init: function () {
            if ( jQuery( '.js-e-form-dw, form[data-validate="true"]' ).length > 0 ) {
                e4.forms.validate.loadTranslations();
                e4.forms.validate.setDefaults();
                e4.forms.validate.bindEvents();
                //e4.forms.validate.loadTranslations(); // Moved to require callback
            }
        },
        setDefaults: function () {
            //Set validation plugin default settings.
            jQuery.validator.setDefaults( {
                errorPlacement: function ( error, element ) {
                    error.addClass( 'help-block text-danger small' ).appendTo( element.closest( '.form-fields' ) );
                },
                submitHandler: function ( form ) {
                    if ( jQuery( form ).is( '.js-e-form-dw' ) === true ) {
                        //Read Dynamicweb spam script from inline script block inside the forms.
                        //DwSpamScripts = DwSpamScripts || {};
                        DwSpamScripts[ form.id ]( form );

                        if ( e4.param[ 'entry-id' ] !== undefined && jQuery( '.js-logbook-entry-form' ).length > 0 ) {
                            jQuery.get( '/Default.aspx?ID=' + jQuery( '.js-logbook-entry-form' ).data( 'overview-page' ) + '&delete-id=' + e4.param[ 'entry-id' ], function () {
                                form.submit();
                            } );
                        }
                        else {
                            form.submit();
                        }
                    }
                }
            } );
        },
        bindEvents: function () {
            //Bind validation method to all Dynamiweb forms and custom forms with data-validate="true" using default settings.
            jQuery( '.js-e-form-dw, form[data-validate="true"]' ).each( function () {
                jQuery( this ).validate();
            } );
        },
        loadTranslations: function () {
            var strLang = e4.settings.lang;
            if ( strLang !== 'en' ) {
                if ( strLang === 'nb' || strLang === 'nn' ) {
                    strLang = 'no';
                }
                else if ( strLang === 'se' ) {
                    strLang = 'sv';
                }
                // Load validation messages fra cdn, and then extend if custom messages are set.
                e4.util.require.js( {
                    //test: undefined,
                    url: '//ajax.aspnetcdn.com/ajax/jquery.validate/1.16.0/localization/messages_' + strLang + '.js',
                    fallback: '/files/system/espresso/design/js/vendor/jquery.validate.messages_' + strLang + '.js',
                    callback: function () {
                        e4.forms.validate.extendTranslations( strLang );
                    }
                } );
            }
            else {
                e4.forms.validate.extendTranslations( strLang );
            }
        },
        extendTranslations: function ( strLang ) {
            if ( e4.settings.forms.validate.messages[ strLang ] !== undefined ) {
                jQuery.extend( jQuery.validator.messages, e4.settings.forms.validate.messages[ strLang ] );
            }

        }
    },
    mailchimp: {
        init: function () {
            if ( jQuery( '.js-e-form-dw-mailchimp-signup' ).length > 0 ) {
                e4.forms.mailchimp.setSubmitHandler();
            }
        },
        setSubmitHandler: function () {
            jQuery( '.js-e-form-dw-mailchimp-signup' ).data( 'validator' ).settings.submitHandler = e4.forms.mailchimp.submitHandler;
        },
        submitHandler: function ( form ) {
            var jForm = jQuery( form );
            jQuery.ajax( {
                type: jForm.attr( 'method' ),
                url: jForm.find( 'input[name="MailChimp_SignUpURL"]' ).val().replace( '/post?', '/post-json?' ),
                data: jForm.serialize(),
                timeout: 1000,
                cache: false,
                dataType: 'jsonp',
                jsonp: 'c',
                // jsonpCallback: 'e4.forms.mailchimp.responseHandler',
                //contentType: 'application/json; charset=utf-8',
                context: form
            } ).always( function ( data ) {
                e4.forms.mailchimp.responseHandler( this, data );
            } );
        },
        responseHandler: function ( form, data ) {
            //console.log( form, data );
            var jForm = jQuery( form ),
                jMailChimpSignUp = jForm.closest( '.js-e-mailchimp-signup' );
            jMailChimpSignUp.find( '.js-e-msg' ).removeClass( 'in' );
            if ( data.result === 'success' ) {
                var jMsgSuccess = jMailChimpSignUp.find( '.js-e-msg-success' );
                if ( jMsgSuccess.html() === '' ) {
                    jMsgSuccess.html( data.msg );
                }
                jMailChimpSignUp.css( {
                    'min-height': ( function () {
                        return jMailChimpSignUp.height();
                    } )()
                } );
                jForm.fadeOut( e4.settings.animation.speed, function () {
                    jMsgSuccess.addClass( 'in' );
                } );
            }
            else if ( data.result === 'error' && data.msg.split( ' ' )[ 0 ] === jForm.find( 'input[name="EMAIL"]' ).val() ) {
                var jMsgMailExists = jMailChimpSignUp.find( '.js-e-msg-mail-exists' );
                if ( jMsgMailExists.html() === '' ) {
                    jMsgMailExists.html( data.msg );
                }
                else {
                    jMsgMailExists.html( jMsgMailExists.html().replace( '{{Email}}', jForm.find( 'input[name="EMAIL"]' ).val() ) );
                }
                jMsgMailExists.addClass( 'in' );
            }
            else {
                var jMsgError = jMailChimpSignUp.find( '.js-e-msg-error' );
                if ( jMsgError.html() === '' ) {
                    jMsgError.html( data.msg );
                }
                jMsgError.addClass( 'in' );
            }
        }
    }
};

/*
jQuery.validator.addMethod( 'localizedNumber', function ( value, domElm ) {
    var regExpLocalizedNumber = new RegExp( '^-?(?:\\d+|\\d{1,3}(?:\\' + vetoryl.logbook.number.intergerSeperator + '\\d{3})+)?(?:\\' + vetoryl.logbook.number.decimalSeperator + '\\d+)?$' );
    return this.optional( domElm ) || regExpLocalizedNumber.test( value );
}, jQuery.validator.messages.number );
*/
