var dechra = {};
( function () {
    'use strict';
    jQuery( function () {
        dechra.logbook.init();
    } );
    dechra.logbook = {
        init: function () {
            this.usernameHandler();
            this.validation();
            this.entries();
            this.loadEditData();
            this.download();
            this.datepicker();
            this.belowTheFold();
        },
        usernameHandler: function () {
            if ( jQuery( '.js-user-email-input' ).length > 0 ) {
                jQuery( '.js-user-email-input' ).on( 'keydown keyup change focus blur', function () {
                    if ( jQuery( this ).val().length > 0 && jQuery( this ).valid() === true ) {
                        jQuery( '.js-unique-email-username' ).val( jQuery( this ).val() + jQuery( this ).data( 'brandsitename' ) );
                    }
                    else {
                        jQuery( '.js-unique-email-username' ).val( '' );
                    }
                } );
            }
        },
        validation: function () {
            jQuery.validator.setDefaults( {
                errorPlacement: function ( error, element ) {
                    error.addClass( 'help-block text-danger small' ).appendTo( element.closest( '.form-fields' ) );
                }
            } );

            jQuery( 'form[name="UserManagementEditForm"], form[name="ExtUserForm"]' ).validate( {
                rules: {
                    UserManagement_Form_NewPasswordConfirm: {
                        equalTo: '[name="UserManagement_Form_NewPassword"]'
                    }
                },
                submitHandler: function ( form ) {
                    if ( form.elements[ 'UserManagement_Form_NewPassword' ].disabled === false ) {
                        form.elements[ 'UserManagement_Form_NewPasswordConfirm' ].value = form.elements[ 'UserManagement_Form_NewPassword' ].value;
                    }
                    form.submit();
                }
            } );
            jQuery( ".js-logbook-entry-form" ).validate( {
                rules: {
                    LogbookDatepicker: {
                        required: true,
                        date: true
                    },
                    EntryDate: {
                        required: true,
                        date: true
                    }
                }
            } );

        },
        entries: function () {
            if ( jQuery( '.js-add-affected-areas' ).length > 0 ) {
                jQuery( '.js-add-affected-areas' ).on( 'click', function () {
                    var choose = jQuery( 'select[name="EntrySymptoms"]' ).val(),
                        specify = jQuery( 'select[name="EntryAffectedAreas"]' ).val(),
                        other = jQuery( '.js-entry-other-symptoms-field' ).val(),
                        symptoms = choose + other,
                        combined = jQuery( 'textarea[name="EntryAffectedAreasCombined"]' ),
                        existingCombinedValue = combined.val(),
                        newLine = '';

                    if ( jQuery( 'select[name="EntrySymptoms"] :selected' ).attr( 'class' ) == 'js-logbook-hide-area' ) {
                        if ( symptoms != '' ) {
                            if ( existingCombinedValue.indexOf( symptoms ) == -1 ) {
                                if ( existingCombinedValue != '' ) {
                                    newLine = '\n';
                                }
                                combined.val( existingCombinedValue + newLine + symptoms ).blur();
                            }
                        }
                    }
                    else {
                        if ( symptoms != '' && specify != '' ) {
                            if ( existingCombinedValue.indexOf( symptoms + ' - ' + specify ) == -1 ) {
                                if ( existingCombinedValue != '' ) {
                                    newLine = '\n';
                                }
                                combined.val( existingCombinedValue + newLine + symptoms + ' - ' + specify ).blur();
                            }
                        }
                    }
                    jQuery( '.form-fields' ).on( 'change keyup keydown paste cut blur', 'textarea', function () {
                        jQuery( this ).height( 0 ).height( this.scrollHeight );
                    } ).find( 'textarea' ).change();
                } );
                jQuery( 'select[name="EntrySymptoms"]' ).on( 'change', function () {
                    if ( jQuery( ':selected', this ).attr( 'class' ) == 'js-entry-other-symptoms' ) {
                        jQuery( '.js-entry-other-symptoms-field' ).slideDown();
                    }
                    else {
                        jQuery( '.js-entry-other-symptoms-field' ).slideUp().val( '' );
                    }
                    if ( jQuery( ':selected', this ).attr( 'class' ) == 'js-logbook-hide-area' ) {
                        jQuery( 'select[name="EntryAffectedAreas"]' ).slideUp();
                    }
                    else {
                        jQuery( 'select[name="EntryAffectedAreas"]' ).slideDown();
                    }
                } );
                setTimeout( function(){
                    jQuery( 'textarea[name="EntryAffectedAreasCombined"]').height( jQuery( 'textarea[name="EntryAffectedAreasCombined"]')[0].scrollHeight );
                }, 50);
            }
            if ( jQuery( '.js-clear-field' ).length > 0 ) {
                jQuery( '.js-clear-field' ).on( 'click', function () {
                    jQuery( '[name="' + jQuery( this ).data( 'clear-field' ) + '"]' ).val( '' ).trigger('change cut blur');
                } );
            }
            /*
            // Not in use yet
            if ( jQuery( '.js-slider-procent' ).length > 0 ) {
                setTimeout( function () {
                    jQuery( '.js-slider-procent' ).slider( {
                        min: -100,
                        max: 0,
                        create: function ( event, ui ) {
                            var defaultValue = 50;
                            if ( jQuery( 'input[name="' + jQuery( this ).data( 'hidden-field' ) + '"]' ).val() != '' ) {
                                defaultValue = jQuery( 'input[name="' + jQuery( this ).data( 'hidden-field' ) + '"]' ).val();
                            }
                            setTimeout( function () {
                                jQuery( '.current-procent' ).text( defaultValue + '%' );
                            }, 50 );
                            jQuery( this ).slider( 'value', -defaultValue );

                            if ( defaultValue > 50 ) {
                                jQuery( this ).css( 'background-color', 'rgba(255, 0, 0, ' + ( ( ( defaultValue * 2 ) / 100 ) - 1 ) + ')' );
                            }
                            if ( defaultValue == 50 ) {
                                jQuery( this ).css( 'background-color', 'rgb(255, 255, 255)' );
                            }
                            if ( defaultValue < 50 ) {
                                jQuery( this ).css( 'background-color', 'rgba(58, 197, 64, ' + ( ( ( ( defaultValue * 2 ) / 100 ) - 1 ) * -1 ) + ')' );
                            }
                        },
                        slide: function ( event, ui ) {
                            var value = ( ui.value * -1 );
                            jQuery( 'input[name="' + jQuery( this ).data( 'hidden-field' ) + '"]' ).val( value );
                            if ( value != 100 && value != 0 ) {
                                jQuery( '.current-procent', this ).text( value + '%' );
                            }
                            else {
                                jQuery( '.current-procent', this ).text( '' );
                            }
                            if ( value > 50 ) {
                                jQuery( this ).css( 'background-color', 'rgba(255, 0, 0, ' + ( ( ( value * 2 ) / 100 ) - 1 ) + ')' );
                            }
                            if ( value == 50 ) {
                                jQuery( this ).css( 'background-color', 'rgb(255, 255, 255)' );
                            }
                            if ( value < 50 ) {
                                jQuery( this ).css( 'background-color', 'rgba(58, 197, 64, ' + ( ( ( ( value * 2 ) / 100 ) - 1 ) * -1 ) + ')' );
                            }
                        }
                    } ).each( function () {

                        var left = jQuery( '<label><b>100%</b></label>' ).css( 'left', '0%' );
                        var middle = jQuery( '<label class="current-procent"></label>' ).css( 'left', '50%' );
                        var right = jQuery( '<label><b>0%</b></label>' ).css( 'left', '100%' );

                        jQuery( this ).append( left, middle, right );
                    } );

                }, 50 );
            }
            */
            if ( jQuery( '.js-slider-increment-down' ).length > 0 ) {
                setTimeout( function () {
                    jQuery( '.js-slider-increment-down' ).slider( {
                        min: -10,
                        max: 0,
                        step: 1,
                        create: function ( event, ui ) {
                            var defaultValue = 5;
                            if ( jQuery( 'input[name="' + jQuery( this ).data( 'hidden-field' ) + '"]' ).val() != '' ) {
                                defaultValue = jQuery( 'input[name="' + jQuery( this ).data( 'hidden-field' ) + '"]' ).val();
                            }
                            jQuery( this ).slider( 'value', -defaultValue );

                            if ( ( defaultValue * 10 ) > 50 ) {
                                jQuery( this ).css( 'background-color', 'rgba(255, 0, 0, ' + ( ( ( defaultValue * 20 ) / 100 ) - 1 ) + ')' );
                            }
                            if ( ( defaultValue * 10 ) == 50 ) {
                                jQuery( this ).css( 'background-color', 'rgb(255, 255, 255)' );
                            }
                            if ( ( defaultValue * 10 ) < 50 ) {
                                jQuery( this ).css( 'background-color', 'rgba(58, 197, 64, ' + ( ( ( ( defaultValue * 20 ) / 100 ) - 1 ) * -1 ) + ')' );
                            }

                        },
                        slide: function ( event, ui ) {
                            var value = ( ui.value * -1 );
                            jQuery( 'input[name="' + jQuery( this ).data( 'hidden-field' ) + '"]' ).val( value );

                            if ( ( value * 10 ) > 50 ) {
                                jQuery( this ).css( 'background-color', 'rgba(255, 0, 0, ' + ( ( ( value * 20 ) / 100 ) - 1 ) + ')' );
                            }
                            if ( ( value * 10 ) == 50 ) {
                                jQuery( this ).css( 'background-color', 'rgb(255, 255, 255)' );
                            }
                            if ( ( value * 10 ) < 50 ) {
                                jQuery( this ).css( 'background-color', 'rgba(58, 197, 64, ' + ( ( ( ( value * 20 ) / 100 ) - 1 ) * -1 ) + ')' );
                            }
                        }
                    } ).each( function () {

                        var opt = jQuery( this ).data().uiSlider.options;

                        var vals = opt.max - opt.min;

                        for ( var i = 0; i <= vals; i++ ) {

                            var el = jQuery( '<label><b>' + ( ( i + opt.min ) * -1 ) + '</b></label>' ).css( 'left', ( i / vals * 100 ) + '%' );

                            jQuery( this ).append( el );
                        }
                    } );
                }, 50 );
            }
            if ( jQuery( '.js-slider-increment-up' ).length > 0 ) {
                setTimeout( function () {
                    jQuery( '.js-slider-increment-up' ).slider( {
                        min: 0,
                        max: 10,
                        step: 1,
                        create: function ( event, ui ) {
                            var defaultValue = 5;
                            if ( jQuery( 'input[name="' + jQuery( this ).data( 'hidden-field' ) + '"]' ).val() != '' ) {
                                defaultValue = jQuery( 'input[name="' + jQuery( this ).data( 'hidden-field' ) + '"]' ).val();
                            }
                            jQuery( this ).slider( 'value', defaultValue );

                            if ( ( defaultValue * 10 ) < 50 ) {
                                jQuery( this ).css( 'background-color', 'rgba(255, 0, 0, ' + ( ( ( ( defaultValue * 20 ) / 100 ) - 1 ) * -1 ) + ')' );
                            }
                            if ( ( defaultValue * 10 ) == 50 ) {
                                jQuery( this ).css( 'background-color', 'rgb(255, 255, 255)' );
                            }
                            if ( ( defaultValue * 10 ) > 50 ) {
                                jQuery( this ).css( 'background-color', 'rgba(58, 197, 64, ' + ( ( ( ( ( defaultValue * 20 ) / 100 ) - 1 ) * -1 ) * -1 ) + ')' );
                            }
                        },
                        slide: function ( event, ui ) {
                            var value = ui.value;
                            jQuery( 'input[name="' + jQuery( this ).data( 'hidden-field' ) + '"]' ).val( value );

                            if ( ( value * 10 ) < 50 ) {
                                jQuery( this ).css( 'background-color', 'rgba(255, 0, 0, ' + ( ( ( ( value * 20 ) / 100 ) - 1 ) * -1 ) + ')' );
                            }
                            if ( ( value * 10 ) == 50 ) {
                                jQuery( this ).css( 'background-color', 'rgb(255, 255, 255)' );
                            }
                            if ( ( value * 10 ) > 50 ) {
                                jQuery( this ).css( 'background-color', 'rgba(58, 197, 64, ' + ( ( ( ( ( value * 20 ) / 100 ) - 1 ) * -1 ) * -1 ) + ')' );
                            }
                        }
                    } ).each( function () {

                        var opt = jQuery( this ).data().uiSlider.options;

                        var vals = opt.max - opt.min;

                        for ( var i = 0; i <= vals; i++ ) {

                            var el = jQuery( '<label><b>' + ( i + opt.min ) + '</b></label>' ).css( 'left', ( i / vals * 100 ) + '%' );

                            jQuery( this ).append( el );
                        }
                    } );
                }, 50 );
            }
            if ( jQuery( '.js-logbook-gallery' ).length > 0 ) {
                jQuery( '.js-logbook-image-loader' ).on( 'click', function () {
                    jQuery( '.js-logbook-image-container' ).html( jQuery( jQuery( this ).data( 'logbook-images' ) ).html() );
                } );
            }
        },
        loadEditData: function () {
            if ( jQuery( '.js-logbook-entry-form' ).length > 0 && jQuery( '.js-logbook-entry-values' ).length > 0 ) {
                var jFormLogbookEntry = jQuery( '.js-logbook-entry-form' ),
                    jFormLogbookEntryFieldData = jQuery( '.js-field-data' );
                jFormLogbookEntryFieldData.each( function () {
                    var jThis = jQuery( this ),
                        jField = jFormLogbookEntry.find( ':input[name="' + jThis.data( 'name' ) + '"]' );
                    //console.log( jField.attr( 'type' ), jThis.data( 'name' ), jThis.data( 'value' ) );
                    if ( jField.attr( 'name' ) === 'EntryUploadPhotos' ) {
                        if ( jThis.data( 'value' ) !== '' ) {
                            jQuery( '<input>' ).attr( {
                                type: 'hidden',
                                name: 'EntryImageUrl',
                                id: 'EntryImageUrl',
                                value: jThis.data( 'value' )
                            } ).appendTo( jFormLogbookEntry );
                        }
                        //console.log( 'append' );
                        jField.on( 'change', function () {
                            jQuery( '#EntryImageUrl' ).val( '' );
                        } );
                        //console.log( 'bind vhange' );
                    }
                    else if ( jField.attr( 'name' ) === 'EntryImageUrl' && jThis.data( 'value' ) !== '' && jQuery( '#EntryImageUrl' ).val() === '' ) {
                        jQuery( '#EntryImageUrl' ).val( jThis.data( 'value' ) );
                    }
                    else if ( jField.attr( 'type' ) === 'checkbox' || jField.attr( 'type' ) === 'radio' ) {
                        //console.log( jThis.attr( 'class' ), jThis.data() );
                        var arrFieldValues = String( jThis.data( 'value' ) ).split( ',' );
                        jQuery.each( arrFieldValues, function ( index, value ) {
                            jField.filter( '[value="' + value + '"]' ).prop( 'checked', true );
                        } );
                    }
                    else {
                        jField.val( jThis.data( 'value' ) );
                    }
                } );
            }
        },
        download: function () {
            var logbookName;
            if ( jQuery( 'a.logbook-download' ).length > 0 ) {
                logbookName = jQuery( '#js-e-canvas' ).data( 'logbook-pdf-download-name' );
            }
            jQuery( 'a.logbook-download' ).each( function () {
                this.href = this.href + '?pdf=true&filename=' + logbookName + '.pdf&leftRightMargin=15&topBottomMargin=25&pagesize=A4';
                if ( e4.param[ 'user-id' ] !== undefined ) {
                    this.href = this.href + '&user-id=' + e4.param[ 'user-id' ];
                }
            } );
        },
        datepicker: function () {
            setTimeout( function () {
                var setDefault;
                if ( jQuery( '#EntryDate' ).val() != '' ) {
                    setDefault = jQuery.datepicker.formatDate( 'mm/dd/yy', new Date( jQuery( '#EntryDate' ).val() ) )
                }
                else {
                    setDefault = new Date();
                }
                jQuery( 'input#logbook-datepicker' ).datepicker( {
                    showButtonPanel: true,
                    dateFormat: "mm/dd/yy",
                    altFormat: "yy-mm-dd",
                    altField: '#EntryDate',
                    maxDate: 0
                } ).datepicker( "setDate", setDefault );
                jQuery.datepicker._gotoToday = function ( id ) {
                    jQuery( id ).datepicker( 'setDate', new Date() ).datepicker( 'hide' ).blur();
                };
            }, 100 );
        },
        belowTheFold: function () {
            if ( jQuery( '.header-overlay' ).length > 0 ) {
                jQuery( '.header-overlay' ).parent().append( '<div class="below-the-fold text-xs-center hidden-md-down"><a href="javascript:void(0);" class="js-below-the-fold"><i class="material-icons">expand_more</i></a></div>' );
                jQuery( '.js-below-the-fold' ).hover( function () {
                    jQuery( '.below-the-fold' ).animate( {
                        bottom: '5px'
                    }, {
                        queue: false
                    }, 200 );
                }, function () {
                    jQuery( '.below-the-fold' ).animate( {
                        bottom: '0'
                    }, {
                        queue: false
                    }, 200 );
                } );
                jQuery( '.js-below-the-fold' ).on( 'click', function () {
                    jQuery( '.below-the-fold' ).fadeOut();
                    jQuery( 'html, body' ).animate( {
                        scrollTop: jQuery( document ).height()
                    }, 1000 );
                } );
                jQuery( window ).scroll( function () {
                    jQuery( '.below-the-fold' ).fadeOut();
                } )
            }
        }
    }
}() );
