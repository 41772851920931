e4.ecom.cart = {
    init: function () {
        if ( jQuery( '.js-e-cart-form' ).length === 1 ) {
            e4.util.require.js( e4.settings.require.handlebars, function () {
                e4.ecom.cart.handlebars.registerHelpers();
                e4.ecom.cart.handlebars.compileTemplates();
                e4.ecom.cart.get();
            } );
            e4.ecom.cart.bindEvents();
        }
    },
    bindEvents: function () {
        jQuery( '.js-e-cart-form' ).on( 'submit', function ( event ) {
            event.preventDefault();
            //e4.ecom.cart.update();
        } ).on( 'focus', '.js-e-orderline-quantity', function () {
            jQuery( this ).select();
        } ).on( 'change', '.js-e-orderline-quantity', function () {
            e4.ecom.cart.updateOrderlines( this );
        } ).on( 'click', '.js-e-orderline-delete', function ( event ) {
            event.preventDefault();
            e4.ecom.cart.deleteOrderline( this );
        } );
        jQuery( '.js-e-cart-search-form' ).find( '.js-e-search-input' ).on( 'keyup', function () {
            if ( this.value.length >= 3 ) {
                jQuery( '.js-e-search' ).find( '.js-e-search-input' ).val( this.value ).focus();
                e4.util.hash.set( 'search' );
            }
        } );
    },
    get: function () {
        e4.ecom.cart.load( {
            type: 'GET'
        } );
    },
    updateOrderlines: function ( domElm ) {
        var jForm = jQuery( domElm.form );
        jForm.find( '.js-e-cart-form-cartcmd' ).val( 'updateorderlines' );
        jForm.find( '.js-e-cart-form-redirect' ).val( e4.settings.ecom.cart.url );
        e4.ecom.cart.load( {
            data: jForm.serialize()
        } );
    },
    deleteOrderline: function ( domElm ) {
        e4.ecom.cart.load( {
            data: {
                key: jQuery( domElm ).closest( '.js-e-orderline' ).attr( 'id' ),
                CartCmd: 'delorderline',
                Redirect: e4.settings.ecom.cart.url
            }
        } );
    },
    load: function ( objAjaxSettings ) {
        var jCart = jQuery( '.js-e-cart' ),
            jqXHR = jQuery.ajax( jQuery.extend( {
                url: e4.settings.ecom.cart.url,
                type: 'POST',
                dataType: 'json',
                beforeSend: function () {
                    jCart.find( '.js-e-loading-overlay' ).addClass( 'is-loading' );
                }
            }, objAjaxSettings ) );
        //console.log( objAjaxSettings );
        jqXHR.done( function ( data ) {
            jQuery( '.js-e-cart-orderlines' ).html( e4.ecom.cart.handlebars.orderline( data ) );
            jQuery( '.js-e-cart-summery-content' ).html( e4.ecom.cart.handlebars.summery( data ) );
        } );
        jqXHR.fail( function ( data, textStatus, errorThrown ) {
            //console.log( jqXHR.getAllResponseHeaders() );
            console.log( data, textStatus, errorThrown );
        } );
        jqXHR.always( function () {
            jCart.find( '.js-e-loading-overlay' ).removeClass( 'is-loading' );
            //console.log( 'always', data );
        } );
    },
    handlebars: {
        registerHelpers: function () {
            if ( window.Handlebars.helpers.loop === undefined ) {
                Handlebars.registerHelper( 'loop', function ( items, options ) {
                    var out = '';
                    for ( var i = 0, l = items.length; i < l; i++ ) {
                        out = out + options.fn( items[ i ] );
                    }
                    return out;
                } );
            }
        },
        compileTemplates: function () {
            e4.ecom.cart.handlebars.orderline = Handlebars.compile( jQuery( '#js-handlebars-tmpl-cart-orderline' ).html() );
            e4.ecom.cart.handlebars.summery = Handlebars.compile( jQuery( '#js-handlebars-tmpl-cart-summery' ).html() );
        }
    }
};

