var e4 = e4 || {},
    dt = {};
( function () {
    'use strict';
    jQuery( function () {
        dt.init();
    } );
    dt.init = function () {
        dt.cookie.init();
        dt.modal.init();
    };
    dt.modal = {};
    dt.modal.init = function () {
        jQuery('#videoModal').modal({
            show:false
        });
        jQuery('.js-ambu-open-modal').on('click', function () {
            //var a = jQuery(event.relatedTarget); // Button that triggered the modal
            var videolink = jQuery(this).data('paragraphalt'); // Extract info from data-* attributes
            // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
            // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
            var modal = jQuery('#videoModal');
            modal.find('.modal-body').html('<iframe src="' + videolink + '" width="100" height="100" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen>');
        });
    };
    dt.cookie = {};
    dt.cookie.init = function () {
        jQuery('.skin-or-ear').removeClass('skin ear');
        jQuery( '.e-nav-global-local .ear a' ).on( 'click', function () {
            jQuery.cookie( 'DechraChosenCondition', 'ear', {
                path: '/'
            }
            );
        } );
        jQuery( '.e-nav-global-local .skin a' ).on( 'click', function () {
            jQuery.cookie( 'DechraChosenCondition', 'skin', {
                path: '/'
            } 
            );
        } );
        jQuery( '.dt-tile-container .skin' ).on( 'click', function () {
            jQuery.cookie( 'DechraChosenCondition', 'skin', {
                path: '/'
            }
            );
        } );
        jQuery( '.dt-tile-container .ear' ).on( 'click', function () {
            jQuery.cookie( 'DechraChosenCondition', 'ear', {
                path: '/'
            }
            );
        } );
       jQuery( '.skin-or-ear a' ).on( 'click', function () {
            var jDechraChosenCondition = jQuery.cookie('DechraChosenCondition');
            if (jDechraChosenCondition !== undefined) {
                var jSkinOrEarHref = jQuery( this ).attr( 'href' );
                var jSkinOrEar = jQuery.cookie('DechraChosenCondition');
                jSkinOrEarHref = jSkinOrEarHref.replace('/skin', '/'+jSkinOrEar);
                jQuery( this ).attr( 'href', jSkinOrEarHref );
            }
        } );
    };
}() );

